import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import DelayContainer from "components/Loading/styled/DelayContainer";
import React, { FC } from "react";
import styled from "styled-components";

interface LoadingProps {
  size?: number;
  title?: string;
  delay?: number;
}

export const Indicator = styled(LoadingOutlined)<{ $size: number }>`
  ${({ $size }) => `
    font-size: ${$size}px;
  `}
`;

const Loading: FC<LoadingProps> = ({ size = 32, title = "Загрузка...", delay = 0 }) => {
  const preloader = (
    <Spin
      indicator={<Indicator
        spin
        $size={size}
      />}
      tip={title}
    />
  );
  return delay ?
    <DelayContainer $delay={delay}>
      {preloader}
    </DelayContainer> :
    <>{preloader}</>;
};

export default Loading;
