import { API_URL } from "config/constants";
import {userStore} from "stores/UserStore";
import axios from "axios";

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      userStore.setAuthToken(null);
    }
    return Promise.reject(error);
  }
)

apiClient.interceptors.request.use(
  (config) => {
    const token = userStore.authToken?.accessToken ?? "";
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: token ? `Bearer ${token}` : "",
      }
    }
  },
)

export default apiClient;
