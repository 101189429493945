import {CloseOutlined} from "@ant-design/icons";
import styled from "styled-components";

const CloseTrigger = styled(CloseOutlined)`
  margin-top: 4px;
  float: right;
  cursor: pointer;
`;

export default CloseTrigger;
