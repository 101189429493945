import {FloatingIndicator} from "pages/editor/components/SaveIndicator/styled";
import {SaveOutlined} from "@ant-design/icons";
import {observer} from "mobx-react-lite";
import React, {FC} from "react";
import useStores from "hooks/useStores";

const SaveIndicator: FC = observer(() => {
  const {editorStore} = useStores();
  return editorStore.saving ?
    <FloatingIndicator title="Сохранение">
      <SaveOutlined />
    </FloatingIndicator> :
    null;
});

export default SaveIndicator;
