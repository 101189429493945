import {Alert, AlertProps, Collapse, Typography} from "antd";
import React, { ErrorInfo } from "react";

const { Panel } = Collapse;
const { Title, Paragraph } = Typography;

type ErrorState = {
  hasError: boolean;
  errorInfo: ErrorInfo | null;
  error: Error | null;
};

interface ErrorBoundaryProps {
  id?: string;
  data?: any;
  errorBlockProps?: Partial<AlertProps>;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state: ErrorState = {
    hasError: false,
    errorInfo: null,
    error: null,
  };

  constructor(props: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(props);
    this.state = {
      hasError: false,
      errorInfo: null,
      error: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.log("-".repeat(32));
    // eslint-disable-next-line no-console
    console.log(error);
    // eslint-disable-next-line no-console
    console.log("-".repeat(32));
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      const { error, errorInfo } = this.state;
      const { id, data } = this.props;
      const componentStack = errorInfo ? errorInfo.componentStack : null;

      const message = <>
        <Title level={4}>
          {`Ошибка выполнения${id ? ` в ${id}` : ""}`}
        </Title>
        <Paragraph type="danger">
          Ошибка: {`${error}`}
        </Paragraph>
        <Collapse>
          <Panel key="more" header="Подробнее...">
            <pre
              style={{
                marginTop: "16px",
                fontSize: "12px",
                lineHeight: "14px",
                maxHeight: "60vh",
                overflowY: "scroll"
              }}
            >
              Error Stack:{"\n\n"}
              {`${error?.stack}`}
              {"\n"}
              {"\n"}
              Component Stack:{"\n"}
              {typeof componentStack !== "string"
                ? JSON.stringify(componentStack, null, 2)
                : componentStack}
              {"\n"}
              {"\n"}
              {!!data && (
                <>
                  Component Data:{"\n"}
                  {JSON.stringify(data, null, 2)}
                </>
              )}
              </pre>
          </Panel>
        </Collapse>
      </>;

      return (
        <Alert
          type="error"
          {...this.props.errorBlockProps}
          message={message}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
