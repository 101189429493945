import {Node, NodeEditor} from "rete";

const updateNodeConnections = (node: Node, editor: NodeEditor) => {
  const connections = node.getConnections();
  connections.forEach((connection) => {
    editor.connect(connection.output, connection.input, connection.data);
    editor.removeConnection(connection);
  });
}

export default updateNodeConnections;
