import {Space} from "antd";
import styled from "styled-components";

const FormSpace = styled(Space)`
  min-width: 360px;
  max-width: 480px;
  width: 100%;
  
  button[type="submit"] {
    margin-top: 12px;  
  }
`;

export default FormSpace;
