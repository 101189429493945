import React, {FC} from "react";
import styled from "styled-components";

const InstructionsBlock = styled.div`
  max-width: 30vw;
`;

const Instructions: FC = () => <InstructionsBlock>
  <b> Правая кнопка мыши</b> вызывает контекстное меню. <br />
  <b> Если ничего не происходит при нажатии</b> - открываем Dev Tools (F12) {"->"} Console <br />
    Узлы имеют входы и выходы, которые связываются друг с другом. <br />
  Узлы создаются по направлению слева-направо сверху-вниз, чтобы удобнее отслеживать связи визуально.
</InstructionsBlock>;

export default Instructions;
