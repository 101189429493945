import { Popover } from 'antd';
import {observer} from "mobx-react-lite";
import CloseTrigger from "components/Page/styled/CloseTrigger";
import Floater from "components/NavBar/styled/Floater";
import Help from "components/NavBar/Help";
import Instructions from "components/NavBar/Instructions";
import React, {FC, useEffect, useState} from "react";
import sleep from "utils/sleep";
import useStores from "hooks/useStores";

const NavBar: FC = observer(() =>
{
  const {uiStore} = useStores();
  const [isHelpVisible, setHelpVisible] = useState(true);
  const [isInstructionsVisible, setInstructionsVisible] = useState(false);

  // const menu = useMemo(() =>
  //   <Menu>
  //     <Menu.Item key="editor">
  //       <a href="#">Редактор</a>
  //     </Menu.Item>
  //     <Menu.Item key="instruction">
  //       <a href="#">Инструкция</a>
  //     </Menu.Item>
  //   </Menu>
  // , []);

  // <Dropdown overlay={menu} trigger={["click"]}>
  // </Dropdown>

  useEffect(() => {
    setTimeout(() => {
      setHelpVisible(false);
      uiStore.setFirstTime(false);
    }, 5000);
  }, [uiStore]);

  return <>
    <Popover
      content={<Help />}
      placement={"right"}
      visible={isHelpVisible}
    >
      <Popover
        content={<Instructions />}
        placement={"bottomRight"}
        title={<>
          <span>Инструкция</span>
          <CloseTrigger onClick={() => {
            setInstructionsVisible(false);
          }} />
        </>}
        visible={isInstructionsVisible}
      >
        <Floater onClick={async () => {
          const delay = isHelpVisible ? 150 : 1;
          setHelpVisible(false);
          uiStore.setFirstTime(false);
          await sleep(delay);
          setInstructionsVisible(!isInstructionsVisible);
        }} />
      </Popover>
    </Popover>
  </>;
});

export default NavBar;
