// https://ant.design/docs/react/customize-theme#Ant-Design-Less-variables
// https://caniuse.com/css-variables
import {ConfigProvider} from "antd";

export const theme = {
  primaryColor: '#8f2696',
};

export const styledTheme = {
  colors: {
    primary: theme.primaryColor,
    primaryDark: "#671b6c",
    primaryLight: "#9f5fa2",
    light: "#fffaf8",
    dark: "rgba(33, 0, 33)",
    darkTransparent: "rgba(33, 0, 33, 0.8)",
    alt: "#257387",
    altTransparent: "rgba(65, 158, 182, 0.8)",
    altDark: "#0d5669",
    altDarkTransparent: "rgba(33, 123, 147, 0.8)",
    altLight: "#36afce",
    altLightTransparent: "rgba(80, 194, 224, 0.8)",
    warning: {
      hover: "var(--ant-warning-color-hover)",
      active: "var(--ant-warning-color-active)",
    },
  },
  boxShadow: {
    default:
      "0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),\n    0 9px 28px 8px rgba(0, 0, 0, 0.05);",

  },
}

export type StyledTheme = typeof styledTheme;

ConfigProvider.config({
  theme,
});

export default ConfigProvider;
