import {AxiosError} from "axios";

const getErrorMessage = (err: Error | AxiosError | unknown): string => {
  const error = err as AxiosError;
  if (error.isAxiosError) {
    return error.response?.data.message;
  }
  return `${err}`;
}

export default getErrorMessage;
