import { observer } from "mobx-react-lite";
import Backdrop from "pages/editor/styled/Backdrop";
import Editor from "pages/editor/components/Editor";
import ErrorBoundary from "components/ErrorBoundary";
import NavBar from "components/NavBar";
import Page from "components/Page";
import React, { FC } from "react";
import SaveIndicator from "pages/editor/components/SaveIndicator";
import useStores from "hooks/useStores";

const EditorPage: FC = observer(() => {
  const {uiStore} = useStores();
  return <Page fullscreen>
    {uiStore.firstTime && <Backdrop onCancel={() => {
      uiStore.setFirstTime(false);
    }}/>}
    <ErrorBoundary id="Editor">
      <Editor />
    </ErrorBoundary>
    <ErrorBoundary id="NavBar">
      <NavBar />
    </ErrorBoundary>
    <ErrorBoundary id="SaveIndicator">
      <SaveIndicator />
    </ErrorBoundary>
  </Page>;
});

export default EditorPage;
