const errorsByCode: Record<string, string> = {
  401: "Не правильно введены пользователь или пароль",
  500: "На сервере произошла ошибка"
}
const DEFAULT_ERROR = "Что-то пошло не так...";

const getErrorTextByResponseCode = (error: any) => {
  if (!error?.response) {
    return "Сервер не отвечат";
  }
  const code = error.response?.status;
  return errorsByCode[code] ? errorsByCode[code] : DEFAULT_ERROR;
};

export default getErrorTextByResponseCode;
