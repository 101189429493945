import { Layout } from "antd";
import styled from "styled-components";

interface PageContentProps {
  $fullscreen?: boolean
}

const PageContent = styled(Layout.Content)<PageContentProps>`
  margin: 0;
  padding: 0;
  width: 100%;
  ${({$fullscreen}) => $fullscreen ? `
    overflow: hidden;
    max-width: calc(100vw - 0px);
    max-height: calc(100vh - 0px);
  ` : ""
  }
`;

export default PageContent;
