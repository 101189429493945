import { Alert, Button, Divider, Form, Input } from "antd";
import { AuthToken } from "types";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {userStore} from "stores/UserStore";
import FullPageForm from "components/FullPageForm";
import React, { useState } from "react";
import getErrorTextByResponseCode from "pages/credentials/login/utils";
import login from "api/quieries/login";

/**
 * Properties used by Login component
 * @onLogin callback triggered when user login succesfully
 */
export interface LoginProps {
  onLogin: (token: AuthToken) => void;
  onBeforeLogin?: (values: LoginFormValues) => void;
}

/**
 * Login form fields
 */
interface LoginFormValues {
  username: string;
  password: string;
}

/**
 * Represent form to authorize user
 * @param props component properties
 * @returns JSX element
 */
const Login: React.FC<LoginProps> = ({onLogin, onBeforeLogin}) => {
  const [errorMessage, setErrorMessage] = useState("");

  /**
   * Callback used when login form submitted
   * @param values form fields values
   */
  const finishHandler = async (values: LoginFormValues) => {
    try {
      if (onBeforeLogin) {
        onBeforeLogin(values);
      }
      const {data} = await login(values);
      onLogin(data);
    } catch (error: any) {
      if (error.response?.data?.reason === "defaultPassword") {
        userStore.setPasswordChange(true);
      } else {
        setErrorMessage(getErrorTextByResponseCode(error));
      }
    }
  };

  const defaultValues: LoginFormValues = { username: "", password: "" };

  return (
    <FullPageForm title="Войти в редактор">
      {errorMessage && (
        <>
          <Divider />
          <Alert closable
            showIcon
            message={errorMessage}
            onClose={() => setErrorMessage("")}
            type="error" />
        </>
      )}
      <Form autoComplete="off" initialValues={defaultValues} name="login" onFinish={finishHandler}>
        <Divider />

        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите пользователя!",
            },
          ]}>
          <Input placeholder="Пользователь" prefix={<UserOutlined />} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите пароль!",
            },
          ]}>
          <Input.Password placeholder="Пароль" prefix={<LockOutlined />} />
        </Form.Item>

        <Button block htmlType="submit" id="login-button" type="primary">
          Войти
        </Button>
      </Form>
    </FullPageForm>
  );
}

export default Login;
