import Context from "rete-context-menu-plugin-react/src/react-menu/context";
import React, { Component } from "react";
import clsx from 'clsx';

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleSubitems: false,
    };
  }

  onClick = (e) => {
    const {
      item: { onClick },
    } = this.props;
    const { args, onClose } = this.context;

    e.stopPropagation();

    if (onClick) onClick(args);
    onClose();
  };

  render() {
    const {
      item: { title, subitems },
    } = this.props;
    const { visibleSubitems } = this.state;

    const handleClick = this.onClick;
    return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        className={clsx({"item": true, "hasSubitems": subitems})}
        onClick={handleClick}
        onMouseLeave={() => this.setState({ visibleSubitems: false })}
        onMouseOver={() => this.setState({ visibleSubitems: true })}
      >
        {title}
        {subitems && visibleSubitems && (
          <div className="subitems">
            {subitems.map((subitem, index) => (
              <Item key={subitem.title ?? index} item={subitem} />
            ))}
          </div>
        )}
      </div>
    );
  }
}

Item.contextType = Context;

export default Item;
