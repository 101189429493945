import { AuthToken, Credentials } from "types";
import { AxiosResponse } from "axios";
import apiClient from "api/apiClient";

/**
 * Send login request to backend
 * @param creds user credentialst to send to backend
 * @returns response from the server
 */
export default function login(creds: Credentials): Promise<AxiosResponse<AuthToken>> {
  return apiClient.post<AuthToken>("/login", creds);
}
