import styled from "styled-components";

const HelpBlock = styled.span`
  font-size: 130%;
  animation: pulsate 1.5s ease-out;
  animation-iteration-count: infinite;
  color: #111;
  @keyframes pulsate {
    0% {
      color: #111;
    }
    50% {
      color: #86228d;
    }
    100% {
      color: #111;
    }
  }
`;

export default HelpBlock;
