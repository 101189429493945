export const API_URL = `${process.env.REACT_APP_BACKEND_URL}/admin/v1`;
export const ENGINE_ID = "strike-bot@0.1.0";
export const STEP_COMPONENT_TITLE = "Новый шаг";
export const UPDATE_REQUEST_DEBOUNCE_DELAY = 500;
export const ZINDEX_ABOVE_BACKDROP = 2000;
export const CONTEXT_MENU_WIDTH = 150;
export const SOCKET_INPUT_NAME = "Вход";
export const SOCKET_OUTPUT_NAME = "Выход";

export const RENAME_CONTEXT_MENU = {
  Delete: "Удалить",
  Clone: "Дублировать",
};
