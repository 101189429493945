import Context from "rete-context-menu-plugin-react/src/react-menu/context";
import Item from "./Item";
import React from "react";

export default ({ items, position: [x, y], visible, args, onClose }) => {
  if (!visible) return null;

  return (
    <Context.Provider value={{ args, onClose }}>
      <div className="context-menu" style={{ left: `${x}px`, top: `${y}px`}}>
        {items.map((item) => (
          <Item key={item.title} item={item} />
        ))}
      </div>
    </Context.Provider>
  );
};
