import {action, makeAutoObservable} from "mobx";
import {persist} from "mobx-persist";

export default class UiStore {
  @persist
  public firstTime = true;

  public isReady = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action("set ui store is ready")
  public setReady = (isReady: boolean) => {
    this.isReady = isReady;
  }

  @action("set first time flag")
  setFirstTime = (value: boolean) => {
    this.firstTime = value;
  }
}

export const uiStore = new UiStore();
