import logo from "assets/logo.png";
import styled from "styled-components";

/**
 * Define Logo element for Login form
 */
const Logo = styled.img`
  width: 64px;
  height: 64px;
  border: 2px solid #fff;
  background-image: url(${logo});
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.1);
`;

export default Logo;
