import {Component, Engine, Input, Node, NodeEditor, Output, Socket} from "rete";
import {ENGINE_ID} from "config/constants";
import {NodeDataWithContextMenuName, RemoteData} from "types";
import {Step} from "pages/editor/rete/components/Step";
import {cloneDeep} from "lodash";
import ConnectionPlugin from "rete-connection-plugin";
import ContextMenuPlugin from "rete-context-menu-plugin-react";
import ReactMenu from "pages/editor/rete/react-menu";
import ReactRenderPlugin from "rete-react-render-plugin";
import generateId from "utils/generateId";

type Refs =  {editor: NodeEditor, engine: Engine, socket: Socket};
type CreateEditor = (container: HTMLElement, data: RemoteData) => Refs;

type ContextMenuCallbackArgs = {
  node: Node,
}
export type ContextMenuCallback = (args: ContextMenuCallbackArgs) => void;

type NodeItems = Record<string, ContextMenuCallback | boolean>;

export const createEditor: CreateEditor = (container, data) => {

  const socket = new Socket('Strike It!');
  const editor = new NodeEditor(ENGINE_ID, container);
  const engine = new Engine(ENGINE_ID);
  const refs = {editor, engine, socket};

  const addInput: ContextMenuCallback = ({ node }) => {
    node.addInput(new Input(`input_${generateId(10)}_`, "", socket));
    node.update();
    editor.trigger('process');
  }

  const addOutput: ContextMenuCallback = ({ node }) => {
    node.addOutput(new Output(`output_${generateId(10)}_`, "", socket));
    node.update();
    editor.trigger('process');
  }

  const nodeItems: NodeItems = {
    "Добавить вход": addInput,
    "Добавить выход": addOutput,
  };

  editor.use(ConnectionPlugin);
  editor.use(ReactRenderPlugin as any);
  editor.use(ContextMenuPlugin, {
    Menu: ReactMenu,
    searchBar: false,
    delay: 100,
    rename: (component: NodeDataWithContextMenuName) => component.contextMenuName || component.name,
    nodeItems,
  });

  const nodes = cloneDeep(data.nodes || {});
  const stepComponent = new Step(socket, nodes);
  const components = [stepComponent];
  components.forEach((c: unknown) => {
    const cmp = c as Component;
    editor.register(cmp);
    engine.register(cmp);
  });

  return refs;
}

export default createEditor;
