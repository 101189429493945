import { createGlobalStyle } from 'styled-components'

// TODO сделатьфайлы с темой и блоками по страницам
const GlobalStyle = createGlobalStyle`
  html, body, #root {
    font-family: 'Inter', sans-serif;
  }

  .ant-modal-title, .ant-popover-title {
    font-weight: 600;
  }
`

export default GlobalStyle;
