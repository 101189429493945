import {RENAME_CONTEXT_MENU} from '../../../../config/constants';
import { injectItem } from "rete-context-menu-plugin-react/src/utils";
import Menu from "rete-context-menu-plugin-react/src/menu";
import React from "react";
import ReactDOM from "react-dom";
import ReactMenu from "./Menu";

export default class extends Menu {
  constructor(editor, props) {
    super();
    this.props = props;
    this.items = [];
    this.position = [0, 0];
    this.visible = false;
    this.el = document.createElement("div");
    editor.view.container.appendChild(this.el);

    this.render();
  }

  addItem(title, onClick, path = []) {
    injectItem(this.items, title, onClick, path);
    this.render();
  }

  show(x, y, args) {
    this.position = [x, y];
    this.args = args;
    this.visible = true;
    this.render();
  }

  hide() {
    this.visible = false;
    this.render();
  }

  render() {
    const itemsFirst = [];
    const itemsSecond = [];
    this.items.forEach(({title, onClick}) => {
      if (RENAME_CONTEXT_MENU[title]) {
        itemsSecond.push({
          title: RENAME_CONTEXT_MENU[title],
          onClick
        });
      } else {
        itemsFirst.push({
          title,
          onClick
        });
      }
    });
    ReactDOM.render(
      <ReactMenu
        {...this.props}
        args={this.args}
        items={[...itemsFirst, ...itemsSecond]}
        onClose={() => this.hide()}
        position={this.position}
        visible={this.visible}
      />,
      this.el
    );
  }
}

export { Menu };
