import {AxiosError, AxiosResponse} from "axios";
import {Engine, NodeEditor, Socket} from "rete";
import {MutableRefObject, useEffect, useRef} from "react";
import {RemoteData} from "types";
import {STEP_COMPONENT_TITLE, UPDATE_REQUEST_DEBOUNCE_DELAY} from "config/constants";
import {message} from "antd";
import {useMutation, useQuery} from "react-query";
import createEditor from "pages/editor/rete/createEditor";
import getContent from "api/quieries/getContent";
import updateContent from "api/mutations/updateContent";
import useStores from "hooks/useStores";

type UseEditorResult = {
  editorRef: MutableRefObject<NodeEditor | undefined>,
  engineRef: MutableRefObject<Engine | undefined>,
  socketRef: MutableRefObject<Socket | undefined>,
  isError: boolean,
  error: AxiosError | null,
  graphData: RemoteData | undefined,
};
type UseEditor = (container: HTMLElement | null) => UseEditorResult;

const useEditor: UseEditor = (container) => {
  const editorRef = useRef<NodeEditor>();
  const engineRef = useRef<Engine>();
  const socketRef = useRef<Socket>();
  const debounceId = useRef(0);
  const {editorStore} = useStores();

  const {isError, error, data: loadedData} = useQuery<AxiosResponse<RemoteData>, AxiosError>(
    "bot-remote-data",
    getContent,
  );
  const graphData = loadedData?.data;

  const {mutateAsync} = useMutation(updateContent);

  useEffect(() => {
    const initEditor = async () => {
      if (container && graphData) {
        const {editor, socket, engine} = createEditor(container, graphData);
        editorRef.current = editor;
        engineRef.current = engine;
        socketRef.current = socket;

        editor.view.resize();
        editor.trigger("process");

        await editor.fromJSON(graphData);

        editor.on('nodecreate', (node) => {
          if (node.name === "Step" && !node.data?.title) {
            node.data.title = STEP_COMPONENT_TITLE;
          }
        });

        editor.on(
          ["process", "nodecreated", "noderemoved", "connectioncreated", "connectionremoved", "nodedraged"],
          (eventData) => {
            const jsonData = editor.toJSON();
            const updateFn = async () => {
              try {
                editorStore.setSaving(true);
                await mutateAsync(jsonData);
              } catch (err) {
                void message.error(
                  "Не удалось сохранить данные!",
                  3
                )
              } finally {
                editorStore.setSaving(false);
              }
              await engine.abort();
              await engine.process(jsonData);
              // eslint-disable-next-line no-console
              // console.log("Saved!", eventData);
            };
            clearTimeout(debounceId.current);
            debounceId.current = window.setTimeout(() => void updateFn(), UPDATE_REQUEST_DEBOUNCE_DELAY);
          });
      }
    }
    void initEditor();

  }, [container, editorStore, graphData, mutateAsync]);

  useEffect(() => {
    return () => {
      if (editorRef.current) {
        // eslint-disable-next-line no-console
        console.log("editor destroyed");
        // eslint-disable-next-line react-hooks/exhaustive-deps
        editorRef.current.destroy();
      }
    };
  }, []);

  return {
    editorRef,
    engineRef,
    socketRef,
    isError,
    error,
    graphData,
  };
}

export default useEditor;
