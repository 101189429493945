import {BaseNodeProps, BaseNodeState} from "pages/editor/rete/templates/BaseNode";
import { Control, Input, Node, Socket } from "rete-react-render-plugin";
import {EditButton, NodeContainer, NodeControls, NodeInfo} from "pages/editor/rete/templates/Step/styled";
import {EditOutlined} from "@ant-design/icons";
import {IO, Output} from "rete";
import {SOCKET_INPUT_NAME, SOCKET_OUTPUT_NAME} from "config/constants";
import {Typography} from "antd";
import {editorStore} from "stores/EditorStore";
import React from "react";
import clsx from "clsx";

const {Title, Paragraph} = Typography;

class StepNode extends Node {

  props!: BaseNodeProps;
  state: BaseNodeState = {
    outputs: [],
    controls: [],
    inputs: [],
    selected: ""
  };

  //TODO выяснить как правильно рендерить Socket и выставлять title
  bindSocket = (el: HTMLDivElement, type: string, io: IO) => {
    if (["input", "output"].includes(type)) {
      el.title = type === "input" ? SOCKET_INPUT_NAME : SOCKET_OUTPUT_NAME;
    }
    this.props.bindSocket(el, type, io);
  }

  render() {
    const { node, bindControl } = this.props;
    const { outputs, controls, inputs, selected } = this.state;

    const {data, name, id} = node;

    return (
      <NodeContainer className={clsx("node", `${selected}`, `component-${name.toLowerCase()}`)}>
        <NodeInfo>
          <Title ellipsis={{rows: 2}} level={4}>
            {`${data.title || name}`}
          </Title>
          {!!data.content && <Paragraph ellipsis={{rows: 3}}>
            {`${data.content}`}
          </Paragraph>}
        </NodeInfo>
        <NodeControls>
          <div className="input-col">
            {/* Inputs */}
            {inputs.map((input: Input) => (
              <div key={input.key} className="input">
                <Socket
                  innerRef={this.bindSocket}
                  io={input}
                  socket={input.socket}
                  type="input"
                />
                {!input.showControl() && (
                  <div className="input-title">{input.name}</div>
                )}
                {input.showControl() && (
                  <Control
                    className="input-control"
                    control={input.control}
                    innerRef={bindControl}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="output-col">
          {/* Outputs */}
          {outputs.map((output: Output) => (
            <div key={output.key} className="output">
              <div className="output-title">{output.name}</div>
              <Socket
                innerRef={this.bindSocket}
                io={output}
                socket={output.socket}
                type="output"
              />
            </div>
          ))}
          </div>
          {/* Controls */}
          {controls.map((control: Control) => (
            <Control
              key={control.key}
              className="control"
              control={control}
              innerRef={bindControl}
            />
          ))}
        </NodeControls>
        <EditButton
          onClick={() => {
            editorStore.setNodeId(id);
          }}
          title="Изменить"
          type="primary"><EditOutlined /></EditButton>
      </NodeContainer>
    );
  }
}

export default StepNode;
