import {CONTEXT_MENU_WIDTH} from "config/constants";
import styled from "styled-components";

const EditorContainer = styled.div`
  ${({theme: {colors, boxShadow}}) => `
  width: 100vw;
  height: 100vh;
  background-color: ${colors.light};
  .context-menu {
    width: ${CONTEXT_MENU_WIDTH}px;
    padding: 8px 0;
    background-color: ${colors.darkTransparent};
    border-radius: 4px;
    box-shadow: ${boxShadow.default};
    .item {
      background-color: transparent;
      border-radius: 0;
      padding: 2px 12px;
      border: none;

      &:hover {
        background-color: ${colors.primary};
      }
    }
  }
  .connection {
    .main-path {
      stroke: ${colors.primaryLight};
    }
  }
  .socket {
    background-color: ${colors.primary};
    border-color:${colors.primaryLight};
  }
  `}
`;

export default EditorContainer;
