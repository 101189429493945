import {Button} from "antd";
import {styledTheme} from "config/theme";
import styled from "styled-components";

const {colors, boxShadow} = styledTheme;

export const NodeContainer = styled.div`
  position: relative;
  padding: 12px 16px;

  border-radius: 6px;

  .input-title:empty, .output-title:empty {
    display: none;
  }

  background-color: ${colors.altTransparent};
  border-color: ${colors.altDarkTransparent};
  
  &:hover{
    background-color: ${colors.altLightTransparent};
    border-color: ${colors.alt};
  }

  &.selected {
    opacity: 1;
    background-color: ${colors.warning.hover};
    border-color: ${colors.warning.active};
    box-shadow: ${boxShadow.default};
  }
`;

export const NodeInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 20vw;
  min-width: 128px;
`;

export const NodeControls = styled.div`
  width: auto;
  max-width: 20vw;
  min-width: 128px;
  display: flex;
  .input-col {
    width: 50%;
  }
  .output-col {
    width: 50%;
  }
  .input, .output {
    margin: 0 -14px 4px -14px;
    > .socket:hover {
      transform: scale(1.1);
    }
  }
`;

export const EditButton = styled(Button)`
  border: 2px solid ${colors.primaryDark};
  width: 40px;
  height: 40px;
  padding: 0;
  position: absolute;
  right: -20px;
  top: -20px;
  text-align: center;
  border-radius: 50%;
  > span {
    > svg {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
`;
