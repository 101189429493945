import {Component, Input, Node, Output, Socket} from "rete";
import StepNode from "pages/editor/rete/templates/Step/StepNode";
// eslint-disable-next-line
import {NodeData, WorkerInputs, WorkerOutputs} from "rete/types/core/data";
import {RemoteData} from "types";
import {STEP_COMPONENT_TITLE} from "config/constants";
import sleep from "utils/sleep";

export class Step extends Component {

  public data: Record<string, any> = {}
  public socket: Socket;
  public collection: RemoteData["nodes"];
  public contextMenuName = STEP_COMPONENT_TITLE;

  constructor(socket: Socket, collection: RemoteData["nodes"]) {
    super("Step");
    this.socket = socket;
    this.collection = collection;
    this.data.component = StepNode;
  }

  builder = async (node: Node) => {

    const data = this.collection[node.id];

    if (!data) {
      return;
    }

    const inputs: Record<string, Input> = {};
    const outputs: Record<string, Output> = {};

    for (const inputKey in data.inputs) {
      inputs[inputKey] = new Input(inputKey, "", this.socket, true);
      node.addInput(inputs[inputKey]);
    }

    for (const outputKey in data.outputs) {
      outputs[outputKey] = new Output(outputKey, "", this.socket, true);
      node.addOutput(outputs[outputKey]);
    }

    await sleep(1);

  }

  worker(node: NodeData, inputs: WorkerInputs, outputs: WorkerOutputs) {
    outputs["content"] = node.data.content;
  }

}
