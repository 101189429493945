import {AuthToken} from "types";
import {message} from "antd";
import {observer} from "mobx-react-lite";
import Login from "pages/credentials/login";
import PasswordChange from "pages/credentials/password";
import React, {FC, ReactNode, useEffect, useState} from "react";
import login from "api/quieries/login";
import useStores from "hooks/useStores";

interface CredentialsProps {
  pendingContent: ReactNode;
}

type LastCredentials = {
  username: string,
  password: string,
};

const defaultCredentials: LastCredentials = {
  username: "",
  password: "",
}

const Credentials: FC<CredentialsProps> = observer(({pendingContent, children}) => {
  const { uiStore, userStore } = useStores();
  const allStoresReady = uiStore.isReady && userStore.isReady;
  const [lastCredentials, setLastCredentials] = useState<LastCredentials>({...defaultCredentials});

  const handleLogin = (token: AuthToken) => {
    userStore.setAuthToken(token)
  }

  useEffect(() => {
    if (allStoresReady) {
      setLastCredentials({...defaultCredentials});
    }
  }, [allStoresReady]);

  if (userStore.passwordChangeRequired) {
    return <PasswordChange
      oldPassword={lastCredentials.password}
      onSuccess={async ({username, newPassword}) => {
        try {
          const {data} = await login({
            username,
            password: newPassword
          });
          handleLogin(data);
        } catch (err) {
          void message.success(
            <span>
            Пароль для «{username}» успешно изменен.<br />
            Пожалуйста, авторизуйтесь используя новый пароль.
          </span>,
            5
          );
        } finally {
          userStore.setPasswordChange(false);
        }
      }}
      username={lastCredentials.username}
    />;
  }

  if (!userStore.authToken) {
    return <Login
      onBeforeLogin={({username, password}) => {
        setLastCredentials({username, password});
      }}
      onLogin={handleLogin}
    />;
  }

  return <>{allStoresReady ?
    children :
    pendingContent
  }</>;
});

export default Credentials;
