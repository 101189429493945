import { action , makeAutoObservable } from "mobx";

export default class EditorStore {
  constructor() {
    makeAutoObservable(this);
  }

  public nodeId: number | null = null;

  public saving = false;

  @action("set editableNode")
  setNodeId = (nodeValue: number | null = null) => {
    this.nodeId = nodeValue;
  }

  @action("set save is pending")
  setSaving = (state: boolean) => {
    this.saving = state;
  }

}

export const editorStore = new EditorStore();
