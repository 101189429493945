import { Route, Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import EditorPage from "pages/editor";
import Error404Page from "pages/Error404Page";
import React, { FC } from "react";
import getPath from "utils/getPath";

const Navigation: FC = observer(() => {
  return (
    <Switch>
      <Route exact component={EditorPage} path={[getPath(""), getPath("editor")]} />
      <Route component={Error404Page} />
    </Switch>
  );
});

export default Navigation;
