import {ZINDEX_ABOVE_BACKDROP} from "config/constants";
import styled from "styled-components";

export const FloatingIndicator = styled.div`
  ${({theme: {colors}}) => `
  position: fixed;
  bottom: 16px;
  left: 16px;
  opacity: 0.3;
  z-index: ${ZINDEX_ABOVE_BACKDROP};
  svg {
    width: 32px;
    height: 32px;
    color: ${colors.primaryLight};
  }`}
`;
