import {ZINDEX_ABOVE_BACKDROP} from "config/constants";
import logo from "assets/logo.png"
import styled from "styled-components";

const Floater = styled.img`
  width: 64px;
  height: 64px;
  border: 2px solid #FFF;
  background-image: url(${logo});
  background-size: cover;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 16px;
  box-shadow: 0 2px 6px 3px rgba(0,0,0,0.1);
  cursor: pointer;
  z-index: ${ZINDEX_ABOVE_BACKDROP};
`;

export default Floater;
