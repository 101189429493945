import { Alert, Button, Divider, Form, Input } from "antd";
import {Callbacks} from "rc-field-form/lib/interface";
import {LockFilled, LockOutlined, UserOutlined} from "@ant-design/icons";
import {PasswordRequestData} from "types";
import {useMutation} from "react-query";
import FullPageForm from "components/FullPageForm";
import React, {useState} from "react";
import changePassword from "api/mutations/changePassword";
import getErrorMessage from "api/utils/getErrorMessage";

export interface PasswordChangeProps {
  username?: string;
  oldPassword?: string;
  onSuccess: (values: PasswordRequestData) => void;
}

const PasswordChange: React.FC<PasswordChangeProps> = ({onSuccess, oldPassword = "", username = ""}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState(
    !!oldPassword ? "Необходимо изменить пароль для вашего пользователя" : ""
  );
  const [pending, setPending] = useState(false);

  const defaultValues: PasswordRequestData = {
    username,
    password: oldPassword,
    newPassword: "",
    confirmation: "",
  };

  const {mutateAsync} = useMutation(changePassword);

  const handleFormFinish: Callbacks<PasswordRequestData>['onFinish'] = async (values) => {
    setPending(true);
    try {
      await mutateAsync(values);
      setTimeout(() => {
        onSuccess(values);
      }, 0);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setPending(false);
    }
  }

  return (
    <FullPageForm title="Изменить пароль">
      {(errorMessage || warningMessage) && (
        <>
          <Divider />
          <Alert closable
            showIcon
            message={warningMessage || errorMessage}
            onClose={() => {
              setErrorMessage("");
              setWarningMessage("");
            }}
            type={warningMessage ? "warning" : "error"} />
        </>
      )}
      <Form autoComplete="off" initialValues={defaultValues} name="login" onFinish={handleFormFinish}>
        <Divider />

        <Form.Item
          name="username">
          <Input
            placeholder="Пользователь"
            prefix={<UserOutlined />}
            readOnly={!!username}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{
            required: true,
            message: "Пожалуйста, введите текущий пароль",
          }]}>
          <Input.Password
            placeholder="Текущий пароль"
            prefix={<LockFilled />}
            readOnly={!!oldPassword}
          />
        </Form.Item>

        <Form.Item
          name="newPassword"
          rules={[{
            required: true,
            message: "Пожалуйста, введите новый пароль",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue("username") === value) {
                return Promise.reject(new Error("Пароль не может совпадать с логином"));
              }
              return Promise.resolve();
            },
          }),
          ]}>
          <Input.Password
            placeholder="Новый пароль"
            prefix={<LockOutlined />}
          />
        </Form.Item>

        <Form.Item
          name="confirmation"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите пароль повторно",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Пароли должны совпадать"));
              },
            }),
          ]}>
          <Input.Password
            placeholder="Повторите новый пароль"
            prefix={<LockOutlined />}
          />
        </Form.Item>

        <Button block htmlType="submit" id="change-password-btn" loading={pending} type="primary">
          Изменить
        </Button>
      </Form>
    </FullPageForm>
  );
}

export default PasswordChange;
