import Layout from "components/Page/styled/Layout";
import PageContent from "components/Page/styled/PageContent";
import React, { FC } from "react";

interface PageProps {
  fullscreen?: boolean;
}

const Page: FC<PageProps> = ({ children, fullscreen= false }) => {
  return (
    <Layout>
      <PageContent $fullscreen={fullscreen}>{children}</PageContent>
    </Layout>
  );
};

export default Page;
