import { AuthToken } from "types";
import {action, makeAutoObservable} from "mobx";
import { persist } from "mobx-persist";

/**
 * Mobx store to keep user data, like username, token and etc
 */
export default class UserStore {

  @persist("object")
  public authToken: AuthToken = null;

  public isReady = false;

  public passwordChangeRequired = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Sets access token
   * @param authToken authorization token for current user
   */
  @action("set authToken value")
  public setAuthToken = (authToken: AuthToken) => {
    this.authToken = authToken;
  }

  @action("set user store is ready")
  public setReady = (isReady: boolean) => {
    this.isReady = isReady;
  }

  @action("set password need to be changed")
  public setPasswordChange = (value: boolean) => {
    this.passwordChangeRequired = value;
  }
}

export const userStore = new UserStore();
