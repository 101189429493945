import "antd/dist/antd.variable.min.css";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { StoresProvider, stores } from "stores";
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import AntdThemeProvider, {styledTheme} from "config/theme";
import App from "App";
import GlobalStyle from "config/globalStyles";
import React from "react";
import ReactDOM from "react-dom";
import queryClient from "api/queryClient";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <StoresProvider value={stores}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AntdThemeProvider>
            <StyledThemeProvider theme={styledTheme}>
              <GlobalStyle />
              <App />
            </StyledThemeProvider>
          </AntdThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </StoresProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
