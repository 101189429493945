import { observer } from "mobx-react-lite";
import Credentials from "pages/credentials";
import Loading from "components/Loading";
import LoadingContainer from "components/Loading/styled/LoadingContainer";
import Navigation from "navigation";
import React, {FC} from "react";

const App: FC = observer(() => {
  return <Credentials
    pendingContent={
      <LoadingContainer>
        <Loading delay={3000}/>
      </LoadingContainer>
    }
  >
    <Navigation/>
  </Credentials>;
});

export default App;
