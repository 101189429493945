import { create } from "mobx-persist";
import { editorStore } from "stores/EditorStore";
import { uiStore } from "stores/UiStore";
import { userStore } from "./UserStore";
import React from "react";

export interface StoreWithReadyState {
  isReady: boolean;
  setReady(isReady: boolean): void;
}

export const stores = Object.freeze({
  editorStore,
  uiStore,
  userStore,
});

const hydrate = create({});
const storesWithReadyState: Record<string, StoreWithReadyState> = {
  uiStore,
  userStore
};

Object.entries(storesWithReadyState).map(async ([storeName, storeInstance]) => {
  try {
    await hydrate(storeName, storeInstance);
  } finally {
    storeInstance.setReady(true);
  }
  return null;
});


export const storesContext = React.createContext(stores);
export const StoresProvider = storesContext.Provider;
