import {Form, FormProps, Input, Modal, ModalProps} from "antd";
import {Node} from "rete";
import {observer} from "mobx-react-lite";
import React, {FC, useCallback, useEffect} from "react";

interface EditStepModalProps {
  node: Node | null,
  onCancel: () => void;
  onFinish: FormProps<FormValues>["onFinish"];
}

type FormValues = {
  title: string,
  content: string,
}

const defaultData: FormValues = {
  title: "",
  content: ""
}

const EditStepModal: FC<EditStepModalProps> = observer(({node, onCancel, onFinish}) => {
  const [form] = Form.useForm<FormValues>();

  const onSubmit: ModalProps["onOk"] = useCallback(async () => {
    try {
      const values: FormValues = await form.validateFields();
      if (onFinish) {
        onFinish(values);
      }
    } catch (errorInfo) {
      //
    }
  }, [form, onFinish]);

  useEffect(() => {
    if (node?.data) {
      form.setFieldsValue({...defaultData, ...node?.data});
    }
  }, [form, node]);

  return (<>
    <Modal
      centered
      destroyOnClose
      cancelText="Отмена"
      okText="Сохранить"
      onCancel={onCancel}
      onOk={onSubmit}
      title="Изменить шаг"
      visible={!!node}
      width={640}
    >
      <Form<FormValues>
        form={form}
        layout="vertical"
        name="editStep"
      >
        <Form.Item
          label="Название шага"
          name="title"
          rules={[{ required: true, message: 'Введите название шага' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Ответ бота"
          name="content"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  </>);
});

export default EditStepModal;
