import {Modal} from "antd";
import {observer} from "mobx-react-lite";
import React, {FC} from "react";
import styled from "styled-components";

const BackdropComponent = styled(Modal)`
  visibility: hidden;
`;

interface BackdropProps {
  onCancel: () => void;
}

const Backdrop: FC<BackdropProps> = observer(({onCancel}) => {
  return <BackdropComponent
    destroyOnClose
    keyboard
    visible
    modalRender={() => null}
    onCancel={onCancel}
  />;
});

export default Backdrop;
