import {Row} from "antd";
import FormSpace from "components/FullPageForm/styled/FormSpace";
import Logo from "components/FullPageForm/styled/Logo";
import React, {FC} from "react";
import Title from "antd/lib/typography/Title";

interface FullPageFormProps {
  title?: string;
}

const FullPageForm: FC<FullPageFormProps> = ({title, children}) => {
  return (    <Row align="middle" justify="center" style={{ minHeight: "100vh" }}>
    <FormSpace
      align="center"
      direction="vertical"
      size={0}
    >
      <Logo />
      {!!title && <Title style={{ textAlign: "center" }}>{title}</Title>}
      {children}
    </FormSpace>
  </Row>);
}

export default FullPageForm;
