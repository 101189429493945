import styled from "styled-components";

type DelayContainerProps = { $delay?: number | string };

export const DelayContainer = styled.div<DelayContainerProps>`
  ${({ $delay }: DelayContainerProps) => `
    animation: delay ${$delay}ms forwards;
  `};
  @keyframes delay {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export default DelayContainer;
