import {Alert} from "antd";
import {Node} from "rete";
import {observer} from "mobx-react-lite";
import EditStepModal from "pages/editor/components/EditStepModal";
import EditorContainer from "pages/editor/styled/EditorContainer";
import Loading from "components/Loading";
import LoadingContainer from "components/Loading/styled/LoadingContainer";
import React, {FC, useCallback, useMemo, useState} from "react";
import getErrorMessage from "api/utils/getErrorMessage";
import updateNodeConnections from "utils/updateNodeConnections";
import useEditor from "pages/editor/rete/hooks/UseEditor";
import useStores from "hooks/useStores";

const Editor: FC = observer(() => {
  const {editorStore} = useStores();
  const [container, setContainer] = useState<HTMLElement | null>(null);

  const {editorRef, graphData, isError, error} = useEditor(container);

  const resetSelectedNode = useCallback(() => {
    editorStore.setNodeId(null);
  }, [editorStore]);


  const selectedNodeId = editorStore.nodeId;
  const selectedNode: Node | null = useMemo(() => {
    if (!editorRef.current?.nodes) return null;
    return editorRef.current?.nodes.find((node) => node.id === selectedNodeId) ?? null;
  }, [editorRef, selectedNodeId]);

  return !!graphData ? (<>
    <EditorContainer
      ref={(ref) => ref && setContainer(ref)}
      id="editor"
    />
    <EditStepModal
      node={selectedNode}
      onCancel={resetSelectedNode}
      onFinish={(values) => {
        if (selectedNode && editorRef.current) {
          selectedNode.data = values;
          selectedNode.update();
          editorRef.current.trigger("process");
          updateNodeConnections(selectedNode, editorRef.current);
          resetSelectedNode();
        }
      }}
    />
  </>) : <LoadingContainer>
    {isError  ?
      <Alert showIcon message={getErrorMessage(error)} type="error" /> :
      <Loading />
    }
  </LoadingContainer>;
});

export default Editor;
